@import "./globals";

.CaseStudy {
  text-align: center;
  background-color: $black;
  color: white;
}

@keyframes slideUp {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.CaseStudy__hero {
  height: 480px;

  background-position: top center;
  background-size: cover;

  h1 {
    animation: 0.8s ease-out 0s 1 slideUp;
    letter-spacing: 0;
    font-size: 36px;
    font-weight: $extra-bold;
    padding-top: 180px;
    margin-bottom: 10px;
    margin-top: 0;

    @media only screen and (min-width: 768px) {
      font-size: 100px;
    }
  }

  h2 {
    animation: 0.8s ease-out 0s 1 slideUp;
    font-size: 16px;
    font-weight: $bold;
    margin-top: 0;
  }

  @media only screen and (min-width: 768px) {
    height: 700px;
  }
}

.fade {
  animation: 0.8s ease-out 0s 1 slideUp;
}

.CaseStudy__hero-disney {
  background-position: bottom right;
}

.CaseStudy__hero-disneycruise {
  h1 {
    @media only screen and (min-width: 768px) {
      font-size: 80px;
    }
  }
}

.CaseStudy__hero, .CaseStudy__content {
  padding: 0 44px;
  position: relative;
}

@keyframes deviceSlideUp {
  0% {
    transform: translate(-50%, -40%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

.CaseStudy__device {
  animation: 0.8s ease-out 0s 1 deviceSlideUp;
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 800px;
}

.CaseStudy__device-aulani {
  width: 180px;

  @media only screen and (min-width: 768px) {
    width: 350px;
  }
}

.CaseStudy__device-disneycruise {
  width: 180px;

  @media only screen and (min-width: 768px) {
    width: 330px;
  }
}

.CaseStudy__device-bby {
  max-width: 600px;
}

.CaseStudy__device-misc {
  width: 120px;

  @media only screen and (min-width: 768px) {
    width: 250px;
  }
}

.CaseStudy__device-spacer {
  height: 180px;

  @media only screen and (min-width: 768px) {
    height: 380px;
  }
}

.CaseStudy__summary {
  color: $yellow;
  letter-spacing: 2;
  font-size: 16px;
  font-weight: $semi-bold;
}

.CaseStudy__device-spacer-dh {
  height: 120px;

  @media only screen and (min-width: 768px) {
    height: 240px;
  }
}

.CaseStudy p {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.CaseStudy__stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 80px auto 0;
  max-width: 700px;
}

.CaseStudyStat {
  text-align: left;
  width: 150px;
  margin-bottom: 40px;
}

.CaseStudyStat__label {
  color: $yellow;
  font-weight: $semi-bold;
}

.CaseStudy__line {
  width: 55px;
  margin-left: 0;
  border: 2px solid $yellow;
}

.masonry-grid {
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}

.masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

.video-container iframe, .video-container object, .video-container embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}

.CaseStudy__more-links {
  @include hovery-link;
  padding-bottom: 100px;
  a { // these styles are cribbed from homepage "my-work" section. TODO: mixin
    display: block;
    padding: 10px 0;
    font-size: 40px;
    @media only screen and (min-width: 768px) {
      font-size: 40px;
    }
  }
}
