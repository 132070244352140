@import "./globals";

$CASE_STUDY_WIDTH: 1080px;

.CaseStudySection {
  text-align: left;
  position: relative;
  margin: 80px auto;
  max-width: $CASE_STUDY_WIDTH;

  .CaseStudy__line {
    box-shadow: 2px 2px 8px #222;
  }

  p {
    max-width: none;
    margin: 0 0 30px;
  }

  h2 {
    letter-spacing: 0;
  }
}

.CaseStudySection__label {
  padding-bottom: 20px;
  text-shadow: 2px 2px 8px #222;
  background: transparent;
  z-index: 10;
}

.CaseStudySection__title {
  font-size: 40px;
  font-weight: $extra-bold;
  letter-spacing: 0;
  text-align: left;
  margin: 0;
}

.CaseStudySection img {
  display: block;
  width: 100%;
  margin-bottom: 60px;
}

.CaseStudy__image {
  display: block;
  width: 100%;
  max-width: $CASE_STUDY_WIDTH;
  margin: 80px auto 120px;
}

.video-container {
	position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0;
  max-width: $CASE_STUDY_WIDTH;
  margin: 80px auto;
	overflow:hidden;
}
