$buttonWidth: 180px;
$buttonHeight: 48px;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-24px);
}

.center {
  width: $buttonWidth;
  height: $buttonHeight;
  position: absolute;
}

.btn {
  width: $buttonWidth;
  height: $buttonHeight;
  cursor: pointer;
  background: transparent;
  border: 2px solid #91C9FF;
  border-width: 2px;
  outline: none;
  transition: 1s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

svg {
  position: absolute;
  left: 0;
  top: 0;
  fill: none;
  stroke: #fff;
  stroke-dasharray: 150 480;
  stroke-dashoffset: 150;
  transition: 1s ease-in-out;
}

svg.yellowStroke {
  stroke: #d59638;
  stroke-width: 2px;
  stroke-dasharray: 150 480;
  stroke-dashoffset: 150;
}

.btn:hover {
  transition: 1s ease-in-out;
}

.btn:hover svg {
  stroke-dashoffset: -480;
}

.btn span {
  color: white;
  display: block;
}
