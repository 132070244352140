// fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');
$regular: 400;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;

// colors
$black: #1e1e1e;
$yellow: #faaf40;
$light-gray: #cecece;
$dark-gray: #787878;
$error-red: #ff1e1e;

body {
  font-family: 'Open Sans', sans-serif;
  background-color: $black;
}

// globals, resets
* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

ul {
  list-style-type: none;
  padding: 0;
}

// components
button, .button {
  border: 2px solid;
  padding: 13px 30px;
  background-color: transparent;
  color: white;
  font-weight: $semi-bold;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;

  &.button-yellow {
    border-color: $yellow;
  }

  &.button-borderless {
    border-color: transparent;
  }
}

// back button
a.back {
  padding: 24px 0 0;
  border: none;
  float: left;
  text-align: left;
  display: block;

  color: white;
  text-decoration: none;
  font-weight: $bold;
  letter-spacing: 2px;
  z-index: 100;
}

// fade animating
.fade-in-section {
  opacity: 0;
  transform: translateY(15px);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 0.8s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section-slow{
  transform: translateY(30px);
  transition: opacity 0.6s ease-out, transform 0.8s ease-out;
}

.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

$link-translucent: rgba(255, 255, 255, 0.4);
$link-full: white;


@mixin hovery-link {
  a {
    font-size: 40px;
    font-weight: $extra-bold;
    text-decoration: none;
    transition: 0.4s;
    cursor: pointer;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, $link-full, $link-full 50%, $link-translucent 50%);
    background-size: 200% 100%;
    background-position: 100%;

    @media only screen and (min-width: 768px) {
      font-size: 80px;
    }
  }

  a:hover {
    transition: all 0.3s cubic-bezier(0.000, 0.000, 0.230, 1);
    background-position: 0%;
  }
}
