@import "./globals";

.CaseStudyImage {
  margin-bottom: 60px;

  img {
    margin: 0;
    margin-bottom: 20px;
  }
}

