@import "./globals.scss";

body {
  font-family: 'Open Sans', sans-serif;
}

p {
  font-size: 16px;
}

// globals, resets
* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  letter-spacing: 2px;
}

ul {
  list-style-type: none;
  padding: 0;
}

// components
button, .button {
  border: 2px solid;
  padding: 13px 30px;
  background-color: transparent;
  color: white;
  font-weight: $semi-bold;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;

  &.button-yellow {
    border-color: $yellow;
  }

  &.button-borderless {
    border-color: transparent;
  }
}

.sectionTitle {
  font-weight: $bold;
  font-size: 16px;
}

// layout
.Home {
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

section {
  background-color: $black;
  min-height: 100vh;
  min-width: 176px;
  padding: 50px 30px;

  text-align: center;

  font-size: calc(10px + 2vmin);
  color: white;

  @media only screen and (min-width: 768px) {
    padding-bottom: 80px;
  }
}

// specifics
.hero {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.titles {
  margin: 0;
  color: $light-gray;
  font-weight: $semi-bold;
  letter-spacing: 2px;
  @media only screen and (min-width: 768px) {
    opacity: 0;
  }
}

.desktop-title {
  position: absolute;
  display: none;
  font-size: 16px;
  letter-spacing: 2px;
  @media only screen and (min-width: 768px) {
    display: block;
  }
}

.desktop-illustration {
  top: 30px;
  left: 50px;
}

.desktop-visual {
  top: 50px;
  right: 20px;
  transform: rotate(90deg);
}

.desktop-interaction {
  bottom: 30px;
  right: 50px;
}

.desktop-ux {
  bottom: 110px;
  left: -30px;
  transform: rotate(270deg);
}

.logo {
  max-width: 70vw;
}

.my-work {
  @include hovery-link;

  background-color: $yellow;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .buttonContainer {
    position: absolute;
    bottom: 50px;
    left: 50%;

    @media only screen and (min-width: 768px) {
      bottom: 80px;
    }
  }

  .bg {
    background-position: top center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    transition: opacity 0.6s ease-out;
    will-change: opacity, visibility;
    opacity: 0;
  }

  .disneyBg {
    background-image: url("/img/disney/background.png");
  }
  .disneyCruiseBg {
    background-image: url("/img/disneycruise/background.jpg");
  }
  .aulaniBg {
    background-image: url("/img/aulani/background.jpg");
  }
  .bbyBg {
    background-image: url("/img/bby/background.jpg");
  }
  .dhBg {
    background-image: url("/img/dh/background.png");
  }
  .miscBg {
    background-image: url("/img/misc/header - miscellaneous@2x.png");
  }

  .show {
    opacity: 1;
  }
}

.my-work-content {
  padding: 50px 0;
  z-index: 10;
}

.about-me {
  h2 {
    color: $yellow;
  }

  p {
    max-width: 600px;
    margin: 20px auto;
  }

  a.download {
    width: 300px;
  }
}

.contactLinks {
  margin-top: 100px;
}

.contactContainer {
  position: relative;
  height: 40px;
}

.profile-pic {
  max-width: 360px;
  width: 90%;
  margin-bottom: 20px;
}
