.bungie {
  max-width: 900px;
  margin: 0 auto;
  color: white;
  padding: 30px;

  .description, .caption {
    margin-top: 0;
    margin-bottom: 50px;
  }

  img {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
}
